import type { PageClientSide } from '@readme/backend/models/page/types';
import type { $TSFixMe } from '@readme/iso';
import type { API_CONFIG_FILE_SLUGS } from '@readmeio/gitto/client';
import type Oas from 'oas';
import type { Operation } from 'oas/operation';
import type { ReactElement } from 'react';

import React from 'react';
import { Route } from 'react-router-dom';

import type { AuthInputRef, OAuthFeatureFlag } from '@ui/API/Auth/types';

import Authentication from './Authentication';
import GettingStarted from './GettingStarted';
import MyRequests from './MyRequests';

export interface RealtimePageProps {
  /** Git-backed documents will use `api_config` to indicate the page type instead of slug like in Mongo-backed pages */
  apiConfig?: API_CONFIG_FILE_SLUGS;
  /** The page document to render */
  doc: PageClientSide;
  /** Whether an OAS definition exists, determines if certain routes should be shown */
  hasOasDefinition: boolean;
  /**
   * A [React ref](https://reactjs.org/docs/refs-and-the-dom.html), which can be created as an empty ref and passed in.
   * This is used for focusing on the `<input>`.
   */
  inputRef: AuthInputRef;
  /** Whether Dev Dash in Hub is enabled (to show My Requests page) */
  myRequestsEnabled: boolean;
  /**
   * An `Oas` class instance.
   * @link https://npm.im/oas
   */
  oas: Oas;
  oasPublicUrl: string;
  oauth: OAuthFeatureFlag;
  /** Callback triggered when errors occur in the "Try It!" button flow. */
  onError: (message: string) => void;
  /**
   * An `Operation` class instance. This can be accessed from `apiDefinition` by running `.operation('/pets', 'get')`.
   */
  operation: Operation;
  /**
   * Expose parameters associated with the `Operation` instance. These include path, query, headers, etc.
   */
  operationParams: ReactElement;
  /** The current url path. */
  path?: string;
  /**
   * Does the API definition allow making requests?
   * @link https://docs.readme.com/main/docs/openapi-extensions#disable-the-api-explorer
   */
  requestsEnabled: boolean;
  sidebar: $TSFixMe[];
}

const Realtime = ({
  doc,
  hasOasDefinition,
  inputRef,
  myRequestsEnabled,
  oas,
  oasPublicUrl,
  oauth,
  onError,
  operation,
  operationParams,
  requestsEnabled,
  path,
  sidebar,
}: RealtimePageProps) => {
  if (!doc) return null;

  return (
    <>
      {/* These routes should only be accessible when an OAS definition exists */}
      {!!hasOasDefinition && (
        <>
          <Route path={`${path}/intro/authentication`}>
            <Authentication
              doc={doc}
              inputRef={inputRef}
              oas={oas}
              oasPublicUrl={oasPublicUrl}
              oauth={oauth}
              onError={onError}
              operation={operation}
              requestsEnabled={requestsEnabled}
              sidebar={sidebar}
            />
          </Route>
          <Route path={`${path}/intro/getting-started`}>
            <GettingStarted
              doc={doc}
              inputRef={inputRef}
              myRequestsEnabled={myRequestsEnabled}
              oas={oas}
              oasPublicUrl={oasPublicUrl}
              oauth={oauth}
              onError={onError}
              operation={operation}
              operationParams={operationParams}
              requestsEnabled={requestsEnabled}
              sidebar={sidebar}
            />
          </Route>
        </>
      )}

      {!!myRequestsEnabled && (
        <Route path={`${path}/intro/my-requests`}>
          <MyRequests oas={oas} />
        </Route>
      )}
    </>
  );
};

export default Realtime;
